import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "./interface";

const initialState: User = {
  userState: false,
  currentUser: [],
  invoiceId: [] 
};

const userSlice = createSlice({
  name: "user",
  initialState,

  reducers: {
    onCurrentUserInfo: (state, action: PayloadAction<any[]>) => {
      state.currentUser = action.payload;
    },
    onUserState: (state, action: PayloadAction<boolean>) => {
      state.userState = action.payload;
    },
    // use in the invoice component - retrieve the data for automatic count
    addInvoiceId: (state, action: PayloadAction<any[]>) => {
      state.invoiceId = action.payload;
    }
  },
});
export const {onCurrentUserInfo, onUserState, addInvoiceId} = userSlice.actions;
export default userSlice.reducer;


// ref: https://react-redux.js.org/using-react-redux/usage-with-typescript#define-root-state-and-dispatch-types