import React, { lazy, Suspense, useEffect } from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { paths } from "./interface";
import RequestAuth from "./RequestAuth";

////////////////
//lazy
const Login = lazy(() => import("./components/login/Login"));
const Main = lazy(() => import("./components/main/Main"));
const Projects = lazy(() => import("./components/projects/Projects"));
const Skilled = lazy(() => import("./components/projects/Skilled"));
const UpdateProjects = lazy(
  () => import("./components/projects/UpdateProjects"),
);
const Invoice = lazy(() => import("./components/invoices/Invoice"));

function App() {
  //  Disable right-click using useEffect hook
  useEffect(() => {
    const handleContextMenu = (e:any) => {
      e.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  return (
    <>
      <Router>
        <Suspense
          fallback={
            <div
              style={{ width: "100%", textAlign: "center", marginTop: "1em" }}
            >
              Loading.....
            </div>
          }
        >
          <Routes>
            <Route path={paths.login} element={<Login />} />
            <Route
              path={paths.main}
              element={
                <RequestAuth>
                  <Main />
                </RequestAuth>
              }
            />
            <Route
              path={paths.projects}
              element={
                <RequestAuth>
                  <Projects />
                </RequestAuth>
              }
            />
            <Route
              path={paths.skills}
              element={
                <RequestAuth>
                  <Skilled />
                </RequestAuth>
              }
            />
            <Route
              path={paths.updateProjects}
              element={
                <RequestAuth>
                  <UpdateProjects />
                </RequestAuth>
              }
            />
            <Route
              path={paths.invoice}
              element={
                <RequestAuth>
                  <Invoice />
                </RequestAuth>
              }
            />
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}

export default App;
